import React, { useRef, useEffect } from 'react';
import Image from 'gatsby-image';
import VideoPlaceholder from '../VideoPlaceholder';
import RichText from '../RichText';

import { gsap } from 'gsap';
import ScrollTrigger from 'gsap/ScrollTrigger';
import { inViewTriggerThresholds } from '../../animationGlobals';
import { Button } from 'grommet';

function MediaHero({ eyebrow, title, bodyText, asset, videoCoverImage }) {
  const placeholderPosterFluid = videoCoverImage?.fluid;
  const imageFluid = asset?.fluid;
  const videoSrc = asset?.file?.url;

  return (
    <section  className="media-hero">
      <div className="media-hero__inner">
        <div className="media-hero__content">
          {eyebrow &&
              <p>{eyebrow}</p>
          }
          <h1 className="media-hero__title">{title}</h1>
          <div className="media-hero__body">
            <RichText source={bodyText.json} />
          </div>
        </div>
        <div className="media-hero__media">
          <div className="media-hero__media-inner">
            {imageFluid ? (
              <Image fluid={imageFluid} />
            ) : (
              <VideoPlaceholder
                posterFluid={placeholderPosterFluid}
                src={videoSrc}
              />
            )}
          </div>
        </div>
      </div>
    </section>
  );
}

MediaHero.defaultProps = {
  title: 'Lorem ipsum dolor sit amet',
  bodyText:
    'Duis ligula magna, egestas ac ipsum sit amet, mattis sodales nulla. Duis iaculis consectetur iaculis. Mauris ultricies et nisi vitae tempor.',
  videoCoverImage: {
    fluid: {
      src: '',
    },
  },
  asset: {
    file: {
      url: '',
    },
  },
};

export default MediaHero;
